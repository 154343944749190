import React from "react";
import { observer, inject } from "mobx-react";
import styled from "styled-components";
import { Toggle, ToggleOption } from "../../uikit/Toggle";
import {
  TemperatureIcon,
  ShowIcon,
  HideIcon,
  PolyIcon,
} from "../../uikit/Icons";
import TextFilter from "../TextFilter";

class MapLegend extends React.Component {
  renderLegendIcon(type, n, layer = null) {
    const theme = this.props.store.theme.theme;
    switch (type) {
      case "heatmap":
        return <TemperatureIcon />;
      case "off":
        return <HideIcon />;
      case "markers":
        let color =
          layer !== null ? layer.geoQuestion.color : theme.chartColors[n];
        return <Circle color={color} />;
      case "lines":
        return <Line color={layer.geoQuestion.color} />;
      case "areas":
        return <Rectangle color={layer.geoQuestion.color} />;
      default:
        return type;
    }
  }

  getSpatialFilter = (layer) => {
    return (e) => {
      e.preventDefault();
      this.props.mapView.setSpatialFilter(layer);
    };
  };

  workingClass(layer) {
    const { mapView } = this.props;
    if (mapView.working) {
      if (mapView.spatialFilter.layer === layer) {
        return "working";
      }
      return "not-working";
    }
    return "idle";
  }

  render() {
    const { mapView, ui } = this.props;
    const layers = mapView.layers;
    console.log("mv", mapView.showTextFilter);
    return (
      <Legend>
        <h4>Locatie vragen</h4>
        <ul>
          {layers.map((layer, layerCount) => (
            <li
              key={`legend-layer-${layerCount}`}
              className={this.workingClass(layer)}
            >
              <section>
                <Toggle label={layer.title}>
                  {layer.types.map((type) => (
                    <ToggleOption
                      key={`legend-layer-${layerCount}-${type}`}
                      active={type === layer.activeType}
                      onClick={() => layer.toggleActiveType(type)}
                      title={type}
                    >
                      {this.renderLegendIcon(type, layerCount, layer)}
                    </ToggleOption>
                  ))}
                </Toggle>
              </section>
              {!mapView.working && layer.hasSpatialFilter && (
                <a
                  className="spatial"
                  href="#0"
                  title="Ruimtelijke filter"
                  onClick={this.getSpatialFilter(layer)}
                >
                  <PolyIcon />
                </a>
              )}
              {mapView.showTextFilter && (
                <a
                  className="text-filter"
                  href="#0"
                  title="Tekst filter"
                  onClick={() => {
                    ui.modal = {
                      title: "Tekst filter",
                      children: <TextFilter layer={layer} />,
                      small: true,
                    };
                  }}
                >
                  <SearchIcon />
                </a>
              )}
            </li>
          ))}
        </ul>
        {mapView.hasSources && (
          <>
            <h4>Kaartlagen</h4>
            <ul className="sources">
              {mapView.sources.map((sc, n) => {
                return (
                  <li key={n} className={sc.visible ? "visibile" : "off"}>
                    <button
                      onClick={() => {
                        sc.visible = !sc.visible;
                      }}
                    >
                      {sc.title}
                      <span>{!sc.visible ? <HideIcon /> : <ShowIcon />}</span>
                    </button>
                  </li>
                );
              })}
            </ul>
          </>
        )}

        {mapView.mapStyleList.length > 1 && (
          <>
            <h4>Achtergrond</h4>
            <select
              value={mapView.activeStyle}
              onChange={(e) => {
                mapView.activeStyle = e.currentTarget.value;
              }}
            >
              {mapView.mapStyleList.map((s) => (
                <option key={s.id} value={s.id}>
                  {s.title}
                </option>
              ))}
            </select>
          </>
        )}
      </Legend>
    );
  }
}

const Legend = styled.div`
  position: absolute;
  right: 0;
  top: 0px;
  background-color: #fff;
  padding: 16px;
  width: 240px;
  z-index: 999;

  ul {
    li {
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      section {
        flex: 1 1 auto;
        overflow: hidden;
      }
      a.spatial {
        display: block;
        text-align: right;
        flex: 0 0 16px;
        svg {
          width: 12px;
          fill: ${(props) => props.theme.gray05};
        }
      }

      a.text-filter {
        display: block;
        flex: 0 0 20px;
        margin-left: 4px;
        width: 20px;
        height: 20px;
        text-align: center;
        padding-top: 4px;
        text-decoration: none;
        border-radius: 50%;
        background-color: #eee;
        font-size: 12px;

        svg {
          width: 10px;
          height: 10px;
        }
        &:hover {
          background-color: #ddd;
        }
      }

      &.not-working {
        opacity: 0.2;
      }
    }
  }

  h4 {
    font-size: 11px;
    margin: 8px 0 8px 0;
    padding: 0;
    font-weight: bold;
  }

  .sources {
    li {
      button {
        font-size: 11px;
        outline: 0;
        padding: 0;
        margin: 0;
        width: 100%;
        border: 0;
        display: flex;
        justify-content: space-between;
        span {
          display: block;
          flex: 0 0 20px;
          margin-left: 4px;
          width: 20px;
          height: 20px;
          text-align: center;
          padding-top: 3px;
          text-decoration: none;
          border-radius: 50%;
          background-color: #eee;
          svg {
            width: 14px;
            height: 14px;
          }
        }

        &:hover {
          color: #4cbce1;
          span {
            background-color: #ddd;
          }
        }
      }
      &.off {
        button {
          color: #aaa;
          span {
            svg {
              fill: #aaa;
            }
          }
        }
      }
    }
  }
`;

const Rectangle = styled.span`
  width: 28px;
  height: 14px;
  display: block;
  #border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const Circle = styled.span`
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const Line = styled.span`
  width: 16px;
  height: 4px;
  display: block;
  background-color: ${(props) => props.color};
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

export default inject("store", "ui")(observer(MapLegend));

const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.15 98">
    <path
      d="M39.1,77.3a37.5,37.5,0,0,0,24.4-8.9L93.1,98a3.32,3.32,0,0,0,2.5,1,3.47,3.47,0,0,0,2.5-1,3.38,3.38,0,0,0,0-4.9L68.4,63.5A38.07,38.07,0,1,0,39.1,77.3Zm0-69.4A31.2,31.2,0,1,1,7.9,39.1,31.24,31.24,0,0,1,39.1,7.9Z"
      transform="translate(-1 -1)"
    />
  </svg>
);
