import { decorate, computed } from "mobx";

class Report {
  store;

  constructor(store) {
    this.store = store;
  }

  get total() {
    return this.store.users.size;
  }

  get questionTotal() {
    return this.store.questionStore.questions.size;
  }

  get geoQuestionTotal() {
    return this.store.questionStore.geoQuestions.size;
  }

  get averageAnswerCount() {
    let totalAnswers = 0;
    this.store.users.forEach(user => {
      totalAnswers += user.answers.size;
    });
    return totalAnswers / this.total;
  }

  get averageGeoAnswerCount() {
    let totalAnswers = 0;
    this.store.users.forEach(user => {
      totalAnswers += user.geoAnswers.length;
    });
    return totalAnswers / this.total;
  }

  get questionReportData() {
    return Array.from(this.store.questionStore.questions.values())
      .map(q => ({
        name: q.key,
        value: q.answers.length
      }))
      .sort((a, b) => a.value - b.value);
  }

  get geoQuestionReportData() {
    return Array.from(this.store.questionStore.geoQuestions.values())
      .map(q => ({
        name: q.key,
        value: q.answers.length
      }))
      .sort((a, b) => a.value - b.value);
  }
}

export default decorate(Report, {
  total: computed,
  questionTotal: computed,
  geoQuestionTotal: computed,
  averageAnswerCount: computed,
  averageGeoAnswerCount: computed,
  questionReportData: computed,
  geoQuestionReportData: computed
});
