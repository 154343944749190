import { formatData } from "../services/Api";

export default class Answer {
  questionId = 0;
  question;
  userId = 0;
  value;
  format; //TODO: make int not string

  constructor(data, question) {
    this.questionId = question.id;
    this.question = question;
    this.value = formatData(data.answer, question.format);
    this.userId = data.respondent;
    this.format = question.format;

    this.question.addAnswer(this);
  }

  valueString() {
    if (this.format === "json") {
      return Object.entries(this.value)
        .map(([key, v]) => {
          if (typeof v === "boolean") {
            if (v) {
              return `${key}`;
            } else {
              return "";
            }
          }
          return `${key}: ${v}`;
        })
        .join("\n");
    }
    return this.value;
  }
}
