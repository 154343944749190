import React from "react";
import { observer } from "mobx-react";
import { GeoJSONLayer, MapContext } from "react-mapbox-gl";
export default observer(function MapSource({ source }) {
  const map = React.useContext(MapContext);

  React.useEffect(() => {
    if (!source.loaded && !source.loading) {
      source.load();
    }
  }, [source]);

  if (!source.loaded || !source.visible) return null;

  return (
    <GeoJSONLayer
      data={source.data}
      fillPaint={source.fillPaint}
      linePaint={source.linePaint}
      before="dummy"
    />
  );
});
