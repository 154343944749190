import React from "react";
import { StyledPopup } from "../../uikit/Map";
import { Button } from "../../uikit/Elements";
import styled from "styled-components";
export default class MapPopup extends React.Component {
  renderDescription(d) {
    return d.map((s, index) => {
      if (!s) return "";
      if (typeof s === "object") {
        return (
          <ul className="label-list" key={index}>
            {Object.keys(s).map((key) => (
              <li key={key}>
                <label>{key}:</label> {s[key] ? "Ja" : "Nee"}
              </li>
            ))}
          </ul>
        );
      } else {
        return <p key={index}>{s}</p>;
      }
    });
  }
  render() {
    const { popup, showUserInfo, onClose } = this.props;

    return (
      <StyledPopup>
        <h4>{popup.title}</h4>
        <Scrollable>{this.renderDescription(popup.description)}</Scrollable>
        {popup.files && popup.files.length !== 0 && (
          <ul style={{ marginTop: "16px" }}>
            {popup.files.map((f, n) => (
              <li key={n}>
                <a target="_blank" rel="noopener noreferrer" href={f.image_url !== "" ? f.image_url : f.file_field}>
                  {f.filename}
                </a>
              </li>
            ))}
          </ul>
        )}
        <ul className="actions">
          <li>
            <Button className="primary" onClick={showUserInfo}>
              Respondent
            </Button>
          </li>
          <li>
            <Button onClick={onClose}>sluit</Button>
          </li>
        </ul>
      </StyledPopup>
    );
  }
}

const Scrollable = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;
