import React from "react";
import { observer, inject } from "mobx-react";

class OtherAnswers extends React.Component {
  render() {
    const { store, question } = this.props;

    let otherQ = store.questionStore.getQuestion(question.other);
    let options = sanitizeAndFilter(otherQ.answers);

    const onChange = (e) => {
      otherQ.removeUserFilter();
      if (e.currentTarget.value == "0") {
        return;
      }
      let ids = e.currentTarget.value.split(",");
      // store.toggleUserIds(otherQ.selectedOther);
      // otherQ.selectedOther = ids;
      // store.toggleUserIds(ids);
      // otherQ.addUserFilter(ids, e.currentTarget.)
      let option = options.find((op) => op.ids.join(",") === e.currentTarget.value);
      otherQ.addUserFilter(ids, option.name);
    };

    return (
      <div style={{ marginTop: "8px" }}>
        <select style={{ maxWidth: "100%" }} onChange={onChange} value={otherQ.selectedUserFilterValue}>
          <option value={0}>Anders namelijk...</option>
          {options.map((a, n) => (
            <option key={n} value={a.ids.join(",")}>
              {a.name}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default inject("store")(observer(OtherAnswers));

function sanitizeAndFilter(arr) {
  let result = new Map();

  arr.forEach((a) => {
    let name = a.value.replace(/"/g, "").toLowerCase();
    if (!result.has(name)) {
      result.set(name, {
        ids: [a.userId],
        name
      });
    } else {
      result.get(name).ids.push(a.userId);
    }
  });

  return Array.from(result.values());
}
