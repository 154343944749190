import React from "react";
import { observer, inject } from "mobx-react";
import ChartItem from "./ChartItem";
import { Content } from "../uikit/Frame";
import { GridList, GridListItem } from "../uikit/Grid";
import { Button } from "../uikit/Elements";
import styled from "styled-components";

class AllQuestions extends React.Component {
  render() {
    const { store, ui } = this.props;
    return (
      <Content>
        <GridList>
          {store.questionStore.filterableQuestions.map((question) => (
            <GridListItem key={question.id}>
              <ChartItem question={question} />
            </GridListItem>
          ))}
          {store.questionStore.unfilterableQuestions.map((question) => (
            <GridListItem key={question.id}>
              <OpenQuestion>
                <h2>{question.title}</h2>
                <Button
                  onClick={() => {
                    ui.modal = {
                      title: question.title,
                      children: <AllAnswers question={question} />
                    };
                  }}
                >
                  {question.filteredAnswerCount} antwoorden
                </Button>
              </OpenQuestion>
            </GridListItem>
          ))}
        </GridList>
      </Content>
    );
  }
}

export default inject("store", "ui")(observer(AllQuestions));

function AllAnswers({ question }) {
  return (
    <AllAnswersContainer>
      {question.filteredAnswers.map((answer, n) => (
        <AnswerContent key={n}>
          <PotentialImages value={answer.value} />
        </AnswerContent>
      ))}
    </AllAnswersContainer>
  );
}

const AnswerContent = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid ${(props) => props.theme.gray04};
  margin-bottom: 32px;
`;

const OpenQuestion = styled.div`
  h2 {
    font-weight: bold;
    margin-bottom: 18px;
  }
`;

const AllAnswersContainer = styled.div`
  padding: 32px;
`;

function PotentialImages({ value }) {
  if (typeof value !== "string") {
    return value;
  }

  const r = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm;

  const matches = value.matchAll(r);
  const newValue = value.replaceAll(r, "");

  let images = Array.from(matches).map((match) => match[0]);
  return (
    <div>
      {newValue}
      {images.map((image, n) => (
        <img key={n} src={image} style={{ maxWidth: "100%" }} />
      ))}
    </div>
  );
}
